<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form ref="form" :model="form" label-width="100px" label-position="right" size="mini">
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请填写视频标题"></el-input>
          </el-form-item>
          <el-form-item label="视频封面" prop="content">
            <el-row>
                <el-col :span="4">
                  <img style="width: 200px; height: 200px" :src="form.coverImageUrl"/>
                </el-col>
                <el-col :span="2" :offset="1">
                  <el-upload :http-request="imgoUpload" action="" :show-file-list="false">
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="视频" prop="content">
            <el-input v-model="form.videoUrl" placeholder="请填写视频vid"></el-input>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''">删除</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
        userInfo: {},
        form: { 
            coverImageUrl: "",
            guid: "",
            title: "",
            videoUrl: ""
        }
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getVideoDetail(self.$route.query.guid || null)
  },
  methods: {
    // 获取视频详情
    getVideoDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/api/help-video/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function(response) {
            self.form.guid = response.data.data.guid;
            self.form.coverImageUrl = response.data.data.coverImageUrl;
            self.form.videoUrl = response.data.data.videoUrl;
            self.form.title = response.data.data.title;
          })
          .catch(function(error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      }
    },
    // 保存
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/api/help-video/saveOrUpdate", data, {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function() {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "helpCenterVideoList" });
            })
            .catch(function(error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的管理员信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    // 删除
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.title || "未命名用户") + "？")
        .then(() => {
          self.$axios
            .delete("/api/help-video/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "helpCenterVideoList" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    // 上传图片
    imgoUpload(files) {
        let self = this;
        let img = new FormData()
        img.append('imageArray', files.file)
        self.$axios
            .post("/api/common/image/upload/", img ,{
            headers: { token: self.userInfo.token,'Content-Type':'multipart/form-data'}
            })
            .then(function(res) {
                self.form.coverImageUrl = res.data.data[0]
            })
            .catch(function (error) {
                self.$message({
                    message:error.response.data.message,
                    type: "warning",
                });
            });
    }
  }
};
</script>

<style>
  .el-transfer-panel{
  width: 30%;
}
</style>
